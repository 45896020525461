@font-face {
  font-family: 'Roboto';
  src: url('assets/fonts/Roboto-Regular.eot');
  src: url('assets/fonts/Roboto-Regular.eot?#iefix') format('embedded-opentype'),
    url('assets/fonts/Roboto-Regular.woff2') format('woff2'),
    url('assets/fonts/Roboto-Regular.woff') format('woff'),
    url('assets/fonts/Roboto-Regular.ttf') format('truetype'),
    url('assets/fonts/Roboto-Regular.svg#Roboto-Regular') format('svg');
}

@font-face {
  font-family: 'Roboto-Medium';
  src: url('assets/fonts/Roboto-Medium.eot');
  src: url('assets/fonts/Roboto-Medium.eot?#iefix') format('embedded-opentype'),
    url('assets/fonts/Roboto-Medium.woff2') format('woff2'),
    url('assets/fonts/Roboto-Medium.woff') format('woff'),
    url('assets/fonts/Roboto-Medium.ttf') format('truetype'),
    url('assets/fonts/Roboto-Medium.svg#Roboto-Medium') format('svg');
}

/* .App {
  text-align: center;
} */

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.MuiFormHelperText-root {
  position: absolute;
  bottom: -15px;
  font-size: 0.67rem !important;
  right: 0;
}

// .MuiButton-outlined {
//   color: #006AB6 !important;
//   border-color: #006AB6 !important;
// }
.MuiTextField-root {
  margin-top: 14px !important;
}

.MuiButton-contained {
  background-color: #006AB6 !important;
    line-height: 1 !important;
    padding: 10px 15px !important;
    &.Mui-disabled {
      background-color: rgba(0, 0, 0, 0.2) !important;
    }
}

.MuiSvgIcon-root {
  // margin-right: 0.2em;
}

.MuiAccordion-root {
  margin-top: 10px;

  &.Mui-expanded {
    margin-top: 10px !important;
  }

  &::before {
    background-color: rgba(0, 0, 0, 0) !important;
  }

  .MuiAccordionSummary-root {
    flex-direction: row-reverse;
    background-color: #F4F6F8 !important;
    min-height: auto;
    padding: 0 4px;

    &.Mui-expanded {
      height: 36px;
      min-height: auto !important;
    }

    .MuiAccordionSummary-content {
      margin: 6px 0;
    }
  }

  .MuiAccordionDetails-root {
    padding: 8px 16px 8px 30px;
  }

  .MuiAccordionSummary-expandIconWrapper {
    transform: rotate(-90deg);

    .MuiSvgIcon-root {
      fill: #006AB6;
    }

    &.Mui-expanded {
      transform: rotate(0deg);
    }
  }
}

.MuiPaginationItem-root {
  &.Mui-selected {
    color: #006AB6 !important;
    background-color: transparent !important;
    font-weight: 600;
    border: 1px solid #006AB6;
    &:hover {
      background-color: transparent;
    }
  }
}
.MuiTab-root{
  min-width: auto !important;
  padding: 12px 0 !important;
  margin-right: 20px !important;
  font-weight: bold !important;
  letter-spacing: 1px !important;
  text-transform: unset !important;
  
}
.MuiInput-input.Mui-disabled{
  -webkit-text-fill-color: rgba(0, 0, 0, 1) !important;
}

.custom-icon {
  width: 1em; 
  height: 1em; 
  fill: currentColor; /* Use the current text color */
  display: inline-block;
  font-size: 1.25rem;
}
.MuiMenuItem-root:hover{
  background-color: #006AB6 !important;
  color: #ffffff !important;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
