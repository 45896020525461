.customization-wrapper {
    .listHoverItems{
        display: none;
    }
    .MuiList-root {
        padding-top: 0px;
        padding-bottom: 0px;
        margin-top: -1px;
        margin-bottom: -1px;
        &:last-child{
            border-bottom: 1px solid #ccc;
        }
    }
    .MuiListItem-root:hover{
        // background-color: #f5f5f5;
        .listHoverItems{
            display: flex;
        }
    }
    .subCatList{
        li{
            height: 40px;
            font-size: 15px;
        }
    }
}