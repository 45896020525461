.navbar-brand {
  text-align: left;
  
  img{
    height: 30px;
  }
}
.dropdown-menu {
    border-radius: 0;
    border: 0;
    border-top: 2px solid #000;
    padding: 1em 1.5em 1em 0.2em ;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  
    ul {
      list-style: none;
      padding: 0;
    }
  
    &.show {
      display: block;
    }
  
    li a {
      color: #212121;
      padding: 0.5em 1em;
      display: flex;
      align-items: center;
      gap: 7px;
      letter-spacing: 0.5px;
      &:hover{
        color: #006AB6;
        background-color: transparent;
      }
      svg{
        font-size: 1.25em;
      }

    }
    
  }
  
  .nav-link {
    font-size: 0.875em;
    text-transform: uppercase;
    color: #006AB6;
    font-weight: 500;
    padding-right: .3rem !important;
        padding-left: .3rem !important;
  }
  .navbar-toggler{
    &:focus{
      box-shadow: none;
    }
  }
  .searchIcon {
    position: absolute;
    right: 10px;
  
    &:hover {
      background: transparent;
    }
  }
  
  .right-nav {
    margin: 0;
    list-style: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
  }
  
  .profileMenu {
    .dropdown-menu {
      left: -90px;
  
      &::before {
        right: 10px;
        left: auto;
      }
    }
  }
  
  .addNewButton {
    border: 1px solid #006AB6;
    border-radius: 100%;
    padding: 9px;
  }
  
  .vDivider {
    width: 1px;
    height: 30px;
    background-color: #ccc;
  }
  
  @media screen and (min-width: 993px) {
    .navbar-nav .dropdown-menu {
      top: 46px;
      // left: 10px;
      display: flex;
      opacity: 0;
      transition: all .3s;
      visibility: hidden;
  
      &::before {
        content: "";
        display: block;
        position: absolute;
        // left: 6px;
        bottom: 100%;
        width: 0;
        height: 0;
        border-bottom: 7px solid #000;
        border-top: 7px solid transparent;
        border-left: 7px solid transparent;
        border-right: 7px solid transparent;
      }
    }
    .dropdown{
      &:hover{
        .dropdown-menu {
          display: flex;
          flex-direction: row;
          margin-top: 0;
          opacity: 1;
          transition: all 0.4s;
          visibility: visible;
        }
      }
    }
  }
  
  @media screen and (max-width: 992px) {
    .dropdown-menu.show {
      max-height: 60vh;
      overflow-y: auto;
    }
  }
  