.uploadImageDialog {
    z-index: -1;

    .MuiDialog-paper {
        height: 80%;
    }

    // .MuiToolbar-root{

    //     justify-content: space-between;
    // }
    // .MuiAppBar-root {
    //     background-color: #fff;
    //     color: #000;
    //     box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075);

    // }
    // .MuiTypography-root{
    //     flex: none;
    // }

    // .MuiTextField-root {
    //     margin-top: 0px !important;
    // }
    .imageSearchBox {
        position: absolute;
        left: calc(50% - 250px);
        width: 500px;
       
        .MuiTextField-root{

            width: 100%;
            .MuiOutlinedInput-root {
                border-radius: 20px;
            }
        }
        @media screen and (max-width:768px) {
            width: 300px;
            left: calc(50% - 150px);
        }
    }

    .MuiToggleButtonGroup-root {
        .MuiToggleButton-root {
            border-radius: 24px;
            padding-left: 15px;
            padding-right: 15px;
        }

        .MuiToggleButtonGroup-firstButton {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }

        .MuiToggleButtonGroup-lastButton {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }

        .MuiToggleButtonGroup-middleButton {
            border-radius: inherit;
        }
    }

    .gridBox {
        padding: 2px 10px 0px 10px;
        background-color: #f9f9f9;
        border-radius: 10px;

        .imgName {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .imgHolder {
            text-align: center;
            padding: 5px;
            background-color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100px;
            width: auto;
            overflow: hidden;
        }

        .MuiFormControlLabel-root {
            margin-right: 0;
        }
    }

}