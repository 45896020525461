.breadcrumbText{
    color: #777777;
}
.btn-link-icon{
    img{
    height: 18px;
    width: 18px;
    opacity: 0.5;
    &:hover{
        opacity: 1;
    }
    }
}
.btn-link{
    color: #777777;
    text-decoration: none;
    &:hover{
        color: #000000;
    }
    
}