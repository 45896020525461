
.gridBox{
    padding: 2px 10px 0px 10px;
    background-color: #f9f9f9;
    border-radius: 10px;
    position: relative;

    .imgName{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 14px;
    }
    .imgHolder{
        text-align: center;
        padding: 5px;
        background-color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100px;
        width: auto;
        overflow: hidden;
    }
    .MuiFormControlLabel-root{
        margin-right: 0;
        // position: absolute;
        // top: 20px;
        // left: 5px;
    }
}

