.text-editor {
    padding-top: 6px;

    .note-editor {

        .note-toolbar {
            background-color: #fbfbfb;
            border-bottom: 1px solid #ccc;
            .btn-group {
                border-right: 1px solid #dbdbdb;
            }

            .dropdown-toggle {
                &::after {
                    display: none;
                }
            }
        }

        .note-statusbar {
            background-color: #80808014;
            .note-resizebar {
                padding-top: 0px;
                height: 4px;
            }
        }
    }
}