.actionBtns {
    .addMenu{
        padding-top: 2px;
        .MuiListItemIcon-root{
            min-width: 24px;
        }
        .base-Popper-root{
            z-index: 2;
            .MuiPaper-root{
                box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
                border: 1px solid #ccc;
            }
        }
        .MuiPopper-root{
            z-index: 2;
        }
        .MuiMenuItem-root{
            font-size: 14px;
            &:hover{
                .MuiListItemIcon-root{
                    color: #ffffff;
                }
            }
        }
    }
}