.productTabs {
    .MuiTab-root {
        min-height: 48px;

        &.Mui-selected {
            color: #006AB6;

            &:last-child {
                .custom-icon {
                    fill: #2ca01c;
                }
            }
        }
    }
}
.product-height{
    height: calc( 100vh - 266px );
    overflow: auto;
}