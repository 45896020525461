/* hide scrollbar but allow scrolling */

.column {
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow-y: scroll;
    min-width: max-content;
}

.column::-webkit-scrollbar {
    display: none;
}
.jPUggG{
    background-color: transparent !important;
}