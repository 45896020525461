.statusBg{
    // background-color: #006AB6;
    padding: 1px 8px;
    border-radius: 20px;
    font-size: 13px;
}

hr{
    margin: 0.5rem 0;
}
