body {
  margin: 0;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F4F6F8;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar {
  width: 0.5em;
  height: 0.5em;
}

::-webkit-scrollbar-button {
  background: #d3d3d3;
  height: 0;
}

::-webkit-scrollbar-track-piece {
  background: #d3d3d3;
  border-radius: 3px;
}

::-webkit-scrollbar-thumb {

  background: rgba(0, 0, 0, 0.15);
  border-radius: 3px;
}

.main-container {
  min-height: 450px;
  height: calc(100vh - 165px);
  // overflow: auto;
}

.white-container {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.04);
  padding: 16px;
  min-height: 450px;
  height: calc(100vh - 165px);
  overflow: auto;
}

.white-container-auto {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.04);
  padding: 16px;

  // min-height: 450px;
  // height: calc(100vh - 165px);
  // overflow: auto;
}

.pageHead {
  font-size: 24px;
  font-weight: 300;
  line-height: 33px;
  letter-spacing: 0px;
  text-align: left;
}

.lineTitle {
  font-size: 18px;
  font-weight: 500;
  margin: 0;
  padding: 0;
  color: #853293;
}

.blueHead30Light {
  font-size: 30px;
  font-weight: 300;
  color: #006AB6;
  margin: 0;
}

.text-blue {
  color: #006AB6;
}
.border-blue{
  border-color: #006AB6 !important;
}
.blueHr {
  background-color: #006AB6;
  opacity: 1;
  margin-bottom: -1px;
  // height: 2px !important;
  margin: 16px 0 0 0;
}

.blueHr1 {
  background-color: #006AB6;
  opacity: 1;
}

.purpleHr {
  background-color: #853293;
  opacity: 1;
}

.bg-light-blue {
  background-color: #DEEAF5;
}

.cursor-pointer {
  cursor: pointer;
}

.no-hover {
  &:hover {
    background-color: transparent !important;
  }
}

.fs-18 {
  font-size: 18px !important;
}

.fs-16 {
  font-size: 18px !important;
}

.fs-14 {
  font-size: 14px !important;
}

.fs-13 {
  font-size: 13px !important;
}

.fs-12 {
  font-size: 12px !important;
}

.font-medium {
  font-family: 'Roboto-Medium';
}

.paddingForCost {
  padding-bottom: 280px;
}

.openedRow {
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  transition: opacity 0.3s ease, max-height 0.3s ease;

  &.visible {
    opacity: 1;
    max-height: fit-content;
    /* Set an appropriate max height or remove it based on your content */
    height: 100%;
  }
}

.expandCollapseBtn {
  position: absolute !important;
  left: -19px;
  top: 0px;
  z-index: 2;
  transition: left 0.15s ease;

  @media screen and (max-width:1024px) {
    left: -10px;
    position: fixed !important;
    top: 100px;
    display: block;
  }

  &.moveLeft {
    left: -20px;
  }
}

.leftPanel {
  transition: width 0.15s ease;

  .leftContent {
    padding-top: 20px;
  }

  &.hiddenPanel {
    width: 15px;
    overflow: hidden;

    .leftContent {
      display: none;

    }

    @media screen and (max-width:1024px) {
      width: 350px;
      position: fixed;
      border: 1px solid #dadada;
      -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.20);
      -moz-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.20);
      box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.20);
      border-radius: 0 10px 10px 0;
      height: calc(88vh - 30px);
      z-index: 1;
      overflow: auto;
      -webkit-scrollbar-width: thin;
      scrollbar-width: thin;

      .leftContent {
        display: block;
      }
    }
  }

  &.visiblePanel {
    // min-width: 350px;
    width: 30%;

    // visibility: visible;
    @media screen and (max-width:1200px) {
      width: 35%;
    }

    @media screen and (max-width:1024px) {
      // visibility: hidden;
      width: 15px;
      overflow: hidden;
    }
  }

  &.leftPanelOverlay {
    position: absolute;
    height: 100vh;
    left: 16px;
  }
}



.rightPanel {
  width: 70%;

  &.rp100 {
    width: 100%;
  }

  @media screen and (max-width:1200px) {
    width: 65%;
  }

  @media screen and (max-width:1024px) {
    width: 100%;
  }

}

.floatingCost {
  // padding: 15px 10px;
  // height: 240px;
  width: 300px;
  // position: fixed;
  // bottom: 20px;
  // right: 30px;
  float: right;
  border: 1px solid #006AB6;
  border-radius: 10px;
  background-color: #F3F8FD;
  -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.20);
  -moz-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.20);
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.20);

  .costRow {
    width: 100%;
    padding: 8px 10px;
    border-bottom: 1px solid #BDBDBD;
    display: flex;
    justify-content: end;
    align-items: center;

    span {
      &:first-child {
        padding-right: 10px;
      }

      &:last-child {
        padding: 0 5px 0 10px;
      }
    }
  }

  .rawTotal {
    background-color: #DDEBFA;
    border-radius: 0 0 12px 12px;
    font-weight: bold;
    border-bottom: 0;
  }

  .moreCostRaws {
    // height: 0;
    overflow: hidden;

    &.show {
      height: auto;
      overflow: auto;
    }
  }

  .costExpandBtn {
    position: absolute;
    z-index: 1;
    top: -15px;
    left: 10px;
    border-bottom: 0;
    display: block;
    cursor: pointer;

    img {
      transform: rotate(-90deg);
    }
  }
}

.statusOpen {
  color: #00C800;
  text-transform: uppercase;

  &::before {
    content: "";
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background-color: #00C800;
    margin-right: 4px;

  }
}

.addressSelected {
  font-size: 14px;
  margin-bottom: 10px;
  padding-left: 22px;
}

.swal2-container {
  z-index: 1301;
}