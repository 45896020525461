.search-box {
    display: flex;
    align-items: flex-end;
    position: relative;
  
    .MuiTextField-root {
      width: 100%;
      min-width: 500px;
  
      .MuiInputLabel-root {
        padding-left: 40px;
        // &.Mui-focused{
        //   padding-left: 0;
        // }
      }
  
      .MuiInputBase-input {
        padding-left: 30px;
      }
    }
    .searchClearBtn{
      position: absolute;
      right: 0px;
      color: #e0e0e0;
      cursor: pointer;
      &:hover{
        color: #ccc;
      }
    }
    .goSearch{
      cursor: pointer;
      position: absolute;
      right: -30px;
      &:hover{
        right: -35px;
      }
    }
    .MuiTextField-root{
      margin-top: -14px !important;
    }
  }
  
  @media screen and (max-width:992px) {
    .search-box {
      .MuiTextField-root {
        min-width: auto;
      }
    }
  }