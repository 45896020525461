.fullName {
    font-size: 14px !important;
    font-family: 'Roboto', Segoe UI, Tahoma, Geneva, Verdana, sans-serif;
}



.overlay {
    .form {
        background-color: #fff;
        border: 1px solid #006ab6;
        border-radius: 5px;
        -webkit-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.15);
        -moz-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.15);
        box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.15);

        .postBtn {
            border: 0px;
            border-radius: 5px;
            background-color: #006ab6;
            padding: 5px 10px;
            color: white;
            font-weight: normal;
            margin-left: 15px;
            font-size: 16px;
            cursor: pointer;
            padding: 5px 20px;
            text-transform: uppercase;

            &:hover {
                border: 0;
                background-color: #006ab6;
            }
        }

    }
}

.replySection {
    border-left: 0px solid rgb(235, 235, 235);
    // margin-left: 0px;
    // padding: 0px 0px 0px 20px;
}
.replysection {
    border-left: 0px solid rgb(235, 235, 235);
    // margin-left: 10px;
    // padding: 0px 0px 0px 20px;

    .form {
        margin-left: 22px !important;
        border: 0 !important;
        box-shadow: none;
        .postBtn {
            border: 0px;
            border-radius: 5px;
            background-color: #006ab6;
            padding: 5px 10px;
            color: white;
            font-weight: normal;
            margin-left: 15px;
            font-size: 16px;
            cursor: pointer;
            padding: 5px 20px;
            text-transform: uppercase;

            &:hover {
                border: 0;
                background-color: #006ab6;
            }
        }

        .cancelBtn {
            border: 1px solid #006ab6;
            border-radius: 5px;
            background-color: transparent;
            padding: 5px 10px;
            color: #006ab6;
            font-weight: normal;
            text-transform: uppercase;
            margin-left: 15px;
            font-size: 14px;
            cursor: pointer;
            padding: 5px 20px;
        }

        .userImg {

            .imgdefault {
                width: 30px;
                height: 30px;
                border-radius: 50%;
            }
        }
    }
}
.replyBtn {
    font-weight: normal;
    width: auto;
    padding: 5px 10px;
    border-radius: 5px;
    border: 1px solid #006ab6;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    color: #006ab6;
    margin-top: 10px !important;

    &:hover {
        background-color: transparent;
        color: #000;
    }

    .replyIcon {
        background-image: url(../../../assets/images/icon-arrowBendUpLeft.svg);
        background-size: cover;
        width: 18px;
        height: 18px;
        // filter: invert(67%) sepia(0%) saturate(0%) hue-rotate(110deg) brightness(85%) contrast(84%);
        filter: brightness(0) saturate(100%) invert(26%) sepia(75%) saturate(2291%) hue-rotate(187deg) brightness(90%) contrast(101%);
        margin-right: 5px;
        position: unset !important;
    }

    span {
        margin-left: 0px !important;
    }

}

