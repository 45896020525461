.status-cell {
    &-dropdown-list {
        padding: 5% 7.5%;
    }

    ul {
        margin: 0;
        padding-left: 1rem;

        li {
            list-style: none;
            position: relative;

            &::before {
                content: "";
                border: 4px solid !important;
                border-color: inherit !important;
                border-radius: 50px;
                margin-top: 7px;
                margin-left: -15px;
                position: absolute;
            }
        }
    }

}

.selectStatusCell {
    .MuiTextField-root {
        margin-top: 0px !important;
    }
    .MuiInputBase-root::before{
        border-bottom: 0 !important;
    }
}