// FOr photoArea component
.upload-area {
    border: 2px dashed #007bff;
    border-radius: 15px;
    padding: 20px;
    text-align: center;
    transition: background-color 0.3s;
    cursor: pointer;
}
.upload-area:hover {
    background-color: #f8f9fa;
}
.upload-button {
    margin-top: 20px;
}