// .table>:not(:last-child)>:last-child>* {
//   border-bottom-color: #006AB6;
//   background: #E8F1FC;
// }
.tableContainer {
  overflow: auto;
  height: calc(100vh - 236px);
}

.table {
  width: 100%;
  min-width: 1000px;
  font-size: 14px;
  letter-spacing: 0.05rem;
  color: #262626;

  tr {
    position: relative;

    td {
      vertical-align: middle;
      padding: 2px 5px 2px 18px;

      &:not(:last-child) {
        border-right: 1px solid #ccc;
      }

      &:last-child {
        // position: sticky;
        // right: 0;
      }

    }

    border-bottom: 1px solid #ccc;
    height: 36px;

    &:hover {
      background-color: #f7fbff;

      .actionBtns {
        opacity: 1;

      }

      td {
        &:last-child {
          -webkit-box-shadow: inset 14px 0px 8px -7px rgba(0,0,0,0.05);
          -moz-box-shadow: inset 14px 0px 8px -7px rgba(0,0,0,0.05);
          box-shadow: inset 14px 0px 8px -7px rgba(0,0,0,0.05);
          background-color: #f7fbff;
        }
      }
    }

    .actionBtns {
      opacity: 0;
      display: flex;

      span {
        margin-left: 15px;
      }
    }
  }

  thead {
    tr {
      th {
        position: relative;
        border-bottom-color: #006AB6 !important;
        background: #E8F1FC;
        padding-left: 0;
        padding: 1px 25px 1px 16px;
        vertical-align: middle;
        min-width: 90px;
        max-width: 250px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &:not(:last-child) {
          border-right: 1px solid #cccccc;
        }

        .orderChangeAce {
          display: block;
          position: absolute;
          cursor: pointer;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          color: inherit;
          text-decoration: none;
          opacity: 0.3;

          &:hover {
            // background: #E8F1FC;
            color: #fff;

          }

          &::after {
            content: "";
            position: absolute;
            top: calc(100% - 20px);
            left: calc(100% - 20px);
            margin-top: -7px;
            border-width: 5px;
            border-style: solid;
            border-color: transparent transparent #000 transparent;
          }

        }

        .orderChangeDec {
          display: block;
          position: absolute;
          cursor: pointer;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          color: inherit;
          text-decoration: none;

          &:hover {
            // background: #E8F1FC;
            color: #fff;
          }

          &::after {
            content: "";
            position: absolute;
            top: calc(100% - 20px);
            left: calc(100% - 20px);
            margin-top: -3px;
            border-width: 5px;
            border-style: solid;
            border-color: #000 transparent transparent transparent;
          }
        }

        &:hover {

          .orderChangeAce,
          .orderChangeDec {
            display: contents;
          }
        }

        &:hover {
          .dragCol {
            opacity: 1;
          }
        }

        .dragCol {
          background-image: url(../../assets/images/icon-drag-indicator2.svg);
          background-size: contain;
          width: 26px;
          height: 26px;
          display: inline-block;
          cursor: move;
          background-position-y: 6px;
          background-repeat: no-repeat;
          opacity: 0;
          position: absolute;
          left: -1px;
          top: 2px;

          &:hover {
            opacity: 1;
          }
        }

        &:first-child {
          min-width: unset;
        }

        // &:first-child, &:nth-child(2){
        //   padding-left: 18px;
        // }
      }

    }
  }
}

.table-hover>tbody>tr:hover {
  --bs-table-accent-bg: #f7fbff;
  // color: var(--bs-table-hover-color);
  -webkit-box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.08);
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.08);
}

.viewOptionTabs {
  .MuiTab-root {
    min-height: 48px;
  }
}

// .table-hover {

//     tr {
//       &:hover {
//         --bs-table-accent-bg: #fcfcfc;
//         color: var(--bs-table-hover-color);
//       }
//     }

// }
// .MuiFormControlLabel-root{
//   margin-right: 0;
// }
.MuiPaginationItem-root {
  line-height: 1 !important;
}

// .MuiButton-root{
//   align-items: flex-start;
// }
.editColBtn {
  .MuiIconButton-root {
    font-size: 14px;

    &:hover {
      background-color: rgba($color: #000000, $alpha: 0);
    }
  }
}

.selected-button {
  // background-color: #006AB6; 
  color: #006AB6 !important;
}

.unselected-button {
  // background-color: white;
  color: black !important;
}

.filterSelect {
  .MuiFormControl-root {
    margin-top: -12px !important;
  }
}