@media screen and (min-width:1024px) {
    .expandCollapseBtn {
        // display: none;
    }

}

@media screen and (max-width:1024px) {
    .contact {
        .rightPanel {
            display: none;
        }

        .leftPanel {
            &.visiblePanel {
                width: 100%;
            }
        }
    }
}